import React from "react"
import { graphql } from "gatsby"

import "katex/dist/katex.min.css"
import Layout from "../components/layout"

import { GatsbySeo } from 'gatsby-plugin-next-seo';

import postStyles from "./styles/blog-post.module.scss"

import {siteMetadata} from "../../gatsby-config.js"

const BlogPost = ({ data, pageContext}) => {
  const {slug} = pageContext;
  const { markdownRemark } = data
  return (
    <Layout>
      <GatsbySeo 
        title={markdownRemark.frontmatter.title} 
        author={markdownRemark.frontmatter.author}
        description={markdownRemark.frontmatter.abstract}
        openGraph={{
          url: siteMetadata.siteUrl + slug,
          title: markdownRemark.frontmatter.title,
          description: markdownRemark.frontmatter.abstract
        }}
      />
      <h1 className={postStyles.title}>{markdownRemark.frontmatter.title}</h1>
      <div className={postStyles.metaLine}>
        <p>{markdownRemark.frontmatter.date} 
          &nbsp;  &#9675; &nbsp; {markdownRemark.fields.readingTime.text}
          &nbsp;  &#9675; &nbsp; {markdownRemark.fields.readingTime.words} words
        </p>
      </div>

      <div className={postStyles.contentWrapper}>

      <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
        <p className={postStyles.author} >
          {markdownRemark.frontmatter.author}
        </p>
      </div>

    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        category
        abstract
      }
      fields {
        slug,
        readingTime {
          text
          words
        }
      }
    }
  }
`
