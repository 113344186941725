
const c_env = process.env.NODE_ENV;

const activeUrl = ((c_env === 'development') ? 'localhost:8000/':
  'https://simon.sekavcnik.com');

module.exports = {
  siteMetadata: {
    title: `Simon's Blogchain`,
    description: `Personal blog of Simon Sekavčnik`,
    author: `Simon Sekavčnik`,
    image:"/images/ogimage.png",
    //siteUrl: 'https://www.simon.sekavcnik.com',
    siteUrl: activeUrl,
    twitter: '@SSekavcnik'
  },
  plugins: [
    {
      resolve: 'gatsby-plugin-next-seo',
      options: {
        title:"Simon's Blogchain",
        author:"Simon Sekavcnik",
        openGraph: {
          type: 'website',
          locale: 'en_IE',
          url: 'https://simon.sekavcnik.com/',
          site_name: "Simon's Blogchain",
          images:[
            {
              url: activeUrl.concat('ogimage.png'),
              width: 1200,
              height: 630,
              alt: 'Og Image Alt'
            }
          ]
        },
        twitter: {
          handle: '@SSekavcnik',
          site: '@SSekavcnik',
          cardType: 'website',
          image: activeUrl.concat('ogimage.png'),
        },
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics-gdpr`,
      options: {
        // The property ID; the tracking code won't be generated without it.
        trackingId: "UA-160700455-1", 
        // Optional parameter (default false) - Enable analytics in development mode.
        enableDevelopment: true, // default false
        // Optional parameter (default true) - Some countries (such as Germany) require you to use the _anonymizeIP function for Google Analytics. Otherwise you are not allowed to use it.
        anonymizeIP: true,
        // Optional parameter (default false) - Starts google analytics with cookies enabled. In some countries (such as Germany) this is not allowed.
        autoStartWithCookiesEnabled: false, 
        // Optional parameter - Configuration for react-ga and google analytics 
        reactGaOptions: {
            debug: true,
            gaOptions: {
                sampleRate: 10
            }
        }
      },
    },
    /*{
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-160700455-1",
      },
    },*/
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/favicon.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `blog-pages`,
        path: `${__dirname}/content/blog`,
      },
    },
    {
      resolve: "gatsby-transformer-remark",
      options:{
        plugins: [
          {
            resolve: `gatsby-remark-prismjs`
          },
          {
            resolve: `gatsby-remark-katex`,
            options: {
              strict: `ignore`,
            },
          },
          `gatsby-remark-reading-time`,
        ],
      },
    },
    `gatsby-plugin-sass`,
    {
      resolve: `gatsby-plugin-prefetch-google-fonts`,
      options: {
        fonts: [
          {
            family: `Hind`,
            variants: [`400`, `700`]
          },
        ],
      },
    }
  ],
}
